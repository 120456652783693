.sns-container div.share-sns {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.share-button {
  cursor: pointer;
}

.share-button:hover:not(:active) {
  opacity: 0.75;
}

.app-links-container div {
  display: inline-block;
  vertical-align: middle;
}