div.ranking-container {
  border: solid 2px #000;
  margin: 0 2%;
}

div.ranking-container ul:nth-child(odd) {
  background: #dae0f5;
}

div.ranking-container ul:first-child {
  background: #dddddd;
  border-bottom: dashed 2px #555;
}