div.upload-container {
  margin: 20px 0 20px 20px;
}

button.register-button {
  border-radius: 3px;
}

.upload-container h2 {
  text-align: left;
}

.upload-container p {
  text-align: left;
}

.upload-form input, button {
  font-size: 100%;
}

.upload-result {
  margin: 10px 0;
  border: dashed 2px #ddd;
  border-radius: 5px;
  padding: 5px;
  background: #ffe4c4;
}